import * as THREE from "three";
import Experience from "./Experience.js";

export default class Floor {
  constructor() {
    // Acceso a la experiencia y la escena
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources;

    // Llamar al método para crear el piso
    this.setFloor();
  }

  setFloor() {
    // Cargar la textura del piso desde la ruta correcta
    const floorTexture = new THREE.TextureLoader().load('/assets/textures/piso.jpg');

    // Configurar la textura para que se repita
    floorTexture.wrapS = THREE.RepeatWrapping;
    floorTexture.wrapT = THREE.RepeatWrapping;
    floorTexture.repeat.set(10, 10); // Repite la textura 10 veces en ambas direcciones

    // Crear el material del piso usando la textura cargada
    const floorMaterial = new THREE.MeshStandardMaterial({ map: floorTexture });

    // Crear la geometría del piso (un plano)
    const floorGeometry = new THREE.PlaneGeometry(100, 100); // Tamaño del piso 100x100 unidades

    // Crear la malla del piso combinando la geometría y el material
    const floorMesh = new THREE.Mesh(floorGeometry, floorMaterial);

    // Rotar el piso para que quede horizontal
    floorMesh.rotation.x = -Math.PI / 2; // Rotación de 90 grados en el eje X

    // Ajustar la posición del piso en el eje Y
    floorMesh.position.y = -2;  // Baja el piso un poco para que esté por debajo de otros objetos

    // Permitir que el piso reciba sombras
    floorMesh.receiveShadow = true;

    // Añadir el piso a la escena
    this.scene.add(floorMesh);
  }
}
