import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import Experience from './Experience.js';

export default class ObjectLoader {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;

    // Llamar al método para cargar el objeto GLB
    this.loadGLBObject();
  }

  loadGLBObject() {
    const loader = new GLTFLoader();
    const textureLoader = new THREE.TextureLoader();
    const url = '/assets/models/t-rex.glb';
    const textureUrl = '/assets/textures/hueso.jpg'; // Ruta a la textura del tiranosaurio
    const paperTextureUrl = '/assets/textures/reconocimiento.png'; // Ruta a la textura de la hoja de papel

    // Cargar las texturas
    const trexTexture = textureLoader.load(textureUrl);
    const paperTexture = textureLoader.load(paperTextureUrl);

    loader.load(
      url,
      (gltf) => {
        const object = gltf.scene;

        // Obtener la altura y la posición del objeto para ajustar la hoja de papel
        let objectHeight = 0;
        let objectCenter = new THREE.Vector3();
        object.traverse((child) => {
          if (child.isMesh) {
            // Aplicar la textura al material del tiranosaurio
            child.material.map = trexTexture;
            child.material.needsUpdate = true;

            // Calcular la altura del objeto
            const bbox = new THREE.Box3().setFromObject(child);
            const height = bbox.max.y - bbox.min.y;
            objectHeight = Math.max(objectHeight, height);

            // Calcular el centro del objeto
            bbox.getCenter(objectCenter);
          }
        });

        // Ajustar la posición del objeto para que esté ligeramente por encima del piso
        const floorHeight = -6.7;
        object.position.y = objectHeight / 2 + floorHeight + 0.5;

        // Configura la posición del objeto en el centro de la escena en X y Z
        object.position.set(0, object.position.y, 0);

        // Escalar el objeto para que sea pequeño
        object.scale.set(0.85, 0.45, 0.45);

        // Añadir el objeto a la escena
        this.scene.add(object);

        console.log('Modelo GLB con textura cargado y añadido al centro de la escena');

        // Colocar la hoja de papel en el centro del dinosaurio, inclinada y ajustada a 5 cm del piso
        this.addPaperNextToDinosaurCenter(objectCenter);
      },
      undefined,
      (error) => {
        console.error('Error al cargar el modelo GLB:', error);
      }
    );
  }

  addPaperNextToDinosaurCenter(dinosaurCenter) {
    const paperTexture = new THREE.TextureLoader().load('/assets/textures/reconocimiento.png');
    const paperMaterial = new THREE.MeshBasicMaterial({ map: paperTexture });

    // Crear un plano para la hoja de papel
    const paperGeometry = new THREE.PlaneGeometry(0.5, 0.5); // Ajusta el tamaño según sea necesario
    const paperMesh = new THREE.Mesh(paperGeometry, paperMaterial);

    // Ajustar la posición del plano más cerca del piso
    const offset = 5 / 100; // 5 centímetros en metros

    paperMesh.position.set(0.6, -1.5, -0.2);
    // Rotar el plano para inclinarlo a 45 grados
    paperMesh.rotation.x = 0 // Asegura que el plano esté paralelo al suelo

    paperMesh.rotation.z = 0;  // Inclinar el plano a 45 grados

    paperMesh.rotation.y = 1.5707963267948966;  // Hacer que el plano esté de pie
    // Añadir la hoja de papel a la escena
    this.scene.add(paperMesh);

    console.log('Hoja de papel añadida al centro del dinosaurio, inclinada a 45 grados y subida 5 centímetros del piso');
  }
}
