export default [
  {
    name: "base",
    data: {},
    items: [
      {
        name: "collision",
        source: "/assets/models/scene.glb",
        type: "model",
      },
      {
        name: "door",
        source: "/assets/models/door.glb",
        type: "model",
      },
      {
        name: "vaseAnimation",
        source: "/assets/models/vase.glb",
        type: "model",
      },
      {
        name: "vase",
        source: "/assets/models/vase_idle.glb",
        type: "model",
      },
      {
        name: "Trex",
        source: "/assets/models/tyrannosaurus_rex_skeleton.glb",
        type: "model",
      },
      {
        name: "baked",
        source: "/assets/baked/baked.ktx2",
        type: "texture",
      },
      {
        name: "starduster",
        source: "/assets/img/5.png",
        type: "texture",
      },
      {
        name: "ghostlyFootsteps",
        source: "/assets/img/huayna.png",
        type: "texture",
      },
      {
        name: "apocalypseNow",
        source: "/assets/img/macsoftins.png",
        type: "texture",
      },
      {
        name: "tfg",
        source: "/assets/img/sisdiag.png",
        type: "texture",
      },
      {
        name: "saveTheCrew",
        source: "/assets/img/3.png",
        type: "texture",
      },
      {
        name: "breakIn",
        source: "/assets/img/app.png",
        type: "texture",
      },
      {
        name: "cobayaUnchained",
        source: "/assets/img/6.png",
        type: "texture",
      },
      {
        name: "qomp",
        source: "/assets/img/8.png",
        type: "texture",
      },
      {
        name: "portfolio",
        source:"/assets/img/15.png",
        type: "texture",
      },
      {
        name: "stardusterNote",
        source: "/assets/notes/faemac.png",
        type: "texture",
      },
      {
        name: "qompNote",
        source: "/assets/notes/meet.png",
        type: "texture",
      },
      {
        name: "ghostlyFootstepsNote",
        source: "/assets/notes/siadmi.png",
        type: "texture",
      },
      {
        name: "cobayaUnchainedNote",
        source: "/assets/notes/maciptv.png",
        type: "texture",
      },
      {
        name: "breakInNote",
        source: "/assets/notes/facexpress.png",
        type: "texture",
      },
      {
        name: "portfolioNote",
        source: "/assets/notes/kirito.png",
        type: "texture",
      },
      {
        name: "nextButton",
        source: "/assets/descriptionButtons/next.png",
        type: "texture",
      },
      {
        name: "prevButton",
        source: "/assets/descriptionButtons/preview.png",
        type: "texture",
      },
      {
        name: "liveDemo",
        source: "/assets/descriptionButtons/web.png",
        type: "texture",
      },
      {
        name: "sourceCode",
        source: "/assets/descriptionButtons/source-code.ktx2",
        type: "texture",
      },
      {
        name: "joanArtGallery",
        source: "/assets/img/2.png",
        type: "texture",
      },
      {
        name: "joanArcadeMachine",
        source: "/assets/img/4.png",
        type: "texture",
      },
      {
        name: "joanOS",
        source: "/assets/img/7.png",
        type: "texture",
      },
      {
        name: "vaseTexture",
        source: "/assets/vase/textura.jpg",
        type: "texture",
      },
      {
        name: "portfolioDescription",
        source: "/assets/descriptions/portfolio-description.png",
        type: "texture",
      },
      {
        name: "stardusterDescription",
        source: "/assets/descriptions/faemac.png",
        type: "texture",
      },

      {
        name: "cobayaunchainedDescription",
        source: "/assets/descriptions/maciptv.png",
        type: "texture",
      },

      {
        name: "ghostlyFootstepsDescription",
        source: "/assets/descriptions/siadmi.png",
        type: "texture",
      },
      {
        name: "saveTheCrewDescription",
        source: "/assets/descriptions/sisdent.png",
        type: "texture",
      },
      {
        name: "tfgDescription",
        source: "/assets/descriptions/sisdigdep.png",
        type: "texture",
      },
      {
        name: "breakInDescription",
        source: "/assets/descriptions/facexpress.png",
        type: "texture",
      },
      {
        name: "qompDescription",
        source: "/assets/descriptions/jitsi.png",
        type: "texture",
      },
      {
        name: "apocalypseNowDescription",
        source: "/assets/descriptions/apocalypse-now-description.png",
        type: "texture",
      },
      {
        name: "trophyGold",
        source: "/assets/trophyLogos/trophy-gold.png",
        type: "texture",
      },
      {
        name: "trophySilver",
        source: "/assets/trophyLogos/trophy-silver.png",
        type: "texture",
      },
      {
        name: "trophyBronze",
        source: "/assets/trophyLogos/trophy-bronze.png",
        type: "texture",
      },
      {
        name: "trophyPlatinum",
        source: "/assets/trophyLogos/trophy-platinum.png",
        type: "texture",
      },

      {
        name: "joanArtGalleryDescription",
        source: "/assets/descriptions/altavida.png",
        type: "texture",
      },

      {
        name: "joanArcadeMachineDescription",
        source: "/assets/descriptions/cosap.png",
        type: "texture",
      },

      {
        name: "joanOSDescription",
        source: "/assets/descriptions/scrum.png",
        type: "texture",
      },
      {
        name: "doNotTouchTrophy",
        source: "/assets/trophies/do-not-touch-trophy.png",
        type: "texture",
      },
      {
        name: "doNotTouch",
        source: "/assets/notes/do-not-touch.png",
        type: "texture",
      },

      {
        name: "doorTrophy",
        source: "/assets/trophies/door-trophy.png",
        type: "texture",
      },
      {
        name: "sourceCodeTrophy",
        source: "/assets/trophies/source-code-trophy.png",
        type: "texture",
      },
      {
        name: "liveDemoTrophy",
        source: "/assets/trophies/live-demo-trophy.png",
        type: "texture",
      },
      {
        name: "nextTrophy",
        source: "/assets/trophies/next-trophy.png",
        type: "texture",
      },
      {
        name: "prevTrophy",
        source: "/assets/trophies/prev-trophy.png",
        type: "texture",
      },
      {
        name: "canvasTrophy",
        source: "/assets/trophies/canvas-trophy.png",
        type: "texture",
      },
      {
        name: "platinumTrophy",
        source: "/assets/trophies/platinum-trophy.png",
        type: "texture",
      },
    ],
  },
];
