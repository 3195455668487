import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import Experience from './Experience.js';

export default class Cnc {
  constructor() {
    this.experience = new Experience();
    this.scene = this.experience.scene;
    this.resources = this.experience.resources; // Asegúrate de que 'resources' esté definido

    // Llamar al método para cargar el objeto GLB
    this.loadGLBObject();
  }

  loadGLBObject() {
    const loader = new GLTFLoader();
    const textureLoader = new THREE.TextureLoader();
    const url = '/assets/models/cnc_meachine.glb'; // Ruta al modelo GLB
    const textureUrl = '/assets/textures/hueso.jpg'; // Ruta a la textura

    // Cargar la textura
    const cncTexture = textureLoader.load(textureUrl);

    loader.load(
      url,
      (gltf) => {
        const object = gltf.scene;

        // Obtener la altura del objeto para ajustar la posición
        let objectHeight = 0;
        object.traverse((child) => {
          if (child.isMesh) {
            // Aplicar la textura al material del objeto
            child.material.map = cncTexture;
            child.material.needsUpdate = true;

            // Calcular la altura del objeto
            const bbox = new THREE.Box3().setFromObject(child);
            const height = bbox.max.y - bbox.min.y;
            objectHeight = Math.max(objectHeight, height);
          }
        });

        // Ajustar la posición del objeto para que esté ligeramente por encima del piso
        const floorHeight = -6.7; // Asegúrate de que esto coincida con la altura de tu piso
        object.position.y = objectHeight / 2 + floorHeight + 0.5;

        // Configura la posición del objeto en el centro de la escena
        const roomWidth = 20;
        const roomDepth = 20;
        object.position.set(roomWidth / 2, object.position.y, roomDepth / 2);

        // Escalar el objeto para que sea pequeño
        object.scale.set(0.10, 0.10, 0.10,0.10);

        // Añadir el objeto a la escena
        this.scene.add(object);

        console.log('Modelo GLB con textura cargado y añadido al centro de la escena');
      },
      undefined,
      (error) => {
        console.error('Error al cargar el modelo GLB:', error);
      }
    );
  }
}
